import './App.css';
import { onAuthStateChanged } from "firebase/auth";
import GoogleLogin from "../GoogleLogin";
import Home from "../Home";
import { useState, useEffect, Fragment } from 'react';
import { Routes, Route } from "react-router-dom";

const App = ({auth}) => {

const [signedInUserName, setSignedInUserName] = useState(undefined);

useEffect(() => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      const { displayName, email } = user;
      setSignedInUserName(displayName || email);
      // const uid = user.uid;
      // ...
    } else {
      setSignedInUserName(undefined);
      // User is signed out
      // ...
    }
  })
}, [auth]); // Only run when auth changes
        
  return (
    <Fragment>
      <div className="App">
        <div className="App-header">
          <p>Header piano image will come here</p>
          </div>
          <div className="App-body">
            {signedInUserName ? (
              <Routes>
                <Route path="*" element={<Home auth={auth} signedInUserName={signedInUserName} />} />
              </Routes>
            ) : (
              <Routes>
                <Route path="*" element={<GoogleLogin auth={auth} />} />
              </Routes>
            )}
          </div>
      </div>
    </Fragment>
  );
}

export default App;
