import './LogoutButton.css';

const LogoutButton = ({onLogoutClicked}) => {

return (
    <button className="gsi-material-button" onClick={onLogoutClicked}>
    <div className="gsi-material-button-content-wrapper">
        <span className="gsi-material-button-contents">Log out</span>
        <span style={{display: "none"}}>Log out</span>
    </div>
    </button>
)
};

export default LogoutButton;
