import './Home.css';
import { signOut } from "firebase/auth";
import LogoutButton from "../LogoutButton";

const Home = ({auth, signedInUserName }) => {

  const onGoogleLogoutClicked = async () => {
    await signOut(auth);
  };

  return (
    <div className="Home">
      <p>Welcome {signedInUserName}!</p>
      <LogoutButton onLogoutClicked={onGoogleLogoutClicked} />
    </div>
  );
}

export default Home;
