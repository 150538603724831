import {signInWithRedirect, GoogleAuthProvider } from "firebase/auth";
import GoogleLoginButton from '../GoogleLoginButton';

const GoogleLogin = ({auth}) => {

  const googleProvider = new GoogleAuthProvider();
  googleProvider.addScope('https://www.googleapis.com/auth/contacts.readonly');

  const onGoogleLoginClicked = async () => {
    await signInWithRedirect(auth, googleProvider);
  };

  return (
    <div id="login_div_id">
      <GoogleLoginButton onGoogleLoginClicked={onGoogleLoginClicked} />
    </div>
  );

/*
  const processGoogleLogin = async () => {
    //Process the redirect from google
    try {
      const signInResult = await getRedirectResult(auth);
       // This gives you a Google Access Token. You can use it to access Google APIs.
       // const credential = GoogleAuthProvider.credentialFromResult(signInResult);
       // const token = credential.accessToken;
   
       // The signed-in user info.
       const user = signInResult.user;
       const { email, displayName } = user;
       console.log("GOogle redirected back to me after login");
    //   setNewlySignedInUser(displayName || email);
       // IdP data available using getAdditionalUserInfo(result)
       // ...
    } catch(error) {
      console.log(error);
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      console.error({errorCode, errorMessage,email, credential});
    }
  };

  // On return.
  if (window.sessionStorage.getItem('pending')) {
    window.sessionStorage.removeItem('pending');
    processGoogleLogin();
  } else {
    return (
      <>
     
      <div id="login_div_id">
        {!signedInUserName && 
        <GoogleLoginButton
        onGoogleLoginClicked={onGoogleLoginClicked}
        />
        }
      </div>
      {}
      </>
    );
  }
  */
};

export default GoogleLogin;