import './GoogleLoginButton.css';
import GoogleLogo from './GoogleLogo.svg';

const GoogleLoginButton = ({onGoogleLoginClicked}) => {

return (
    <button className="gsi-material-button" onClick={onGoogleLoginClicked}>
    <div className="gsi-material-button-state"></div>
    <div className="gsi-material-button-content-wrapper">
        <div className="gsi-material-button-icon">
        <img src={GoogleLogo} alt="Google login"/>
        </div>
        <span className="gsi-material-button-contents">Log in with Google</span>
        <span style={{display: "none"}}>Log in with Google</span>
    </div>
    </button>
)
};

export default GoogleLoginButton;
